* {
  /* scrollbar-width: none; */
  scrollbar-width: thin;
  /* overflow-x: hidden; */

  font-family: "Nunito";
}
body {
  margin: 0;
  font-family: "Nunito";
}

code {
  font-family: "Nunito";
}

@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald.ttf") format("ttf");
}
