:root {
  --primary: #166683;
  --accent: #9ff2e0;
  --fc-button-active-bg-color: var(--primary);
  --fc-button-active-border-color: var(--primary);
  --fc-button-bg-color: var(--primary);
  --fc-button-border-color: var(--primary);
  overflow-y: auto;
  scrollbar-width: none;
}
body {
  overflow-y: auto;
  scrollbar-width: none;
}
html,
body {
  translate: no;
}
#root {
  min-height: 100vh;
}
.MuiBox-root.css-k008qs {
  min-height: 100vh;
}

.App {
  text-align: center;
  font-family: "Nunito";
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
 */
/* .App-link {
  color: #61dafb;
}
 */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito-Regular.ttf") format("truetype");
}

body {
  background-color: #f6f1f1;
  font-family: "Nunito";
}

/* ESTILOS PARA INPUT TYPE FILE */

.file-upload .file-upload-select {
  display: block;
  color: #dbdbdb;
  cursor: pointer;
  text-align: left;
  background: #1a242f;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
}
.file-upload .file-upload-select .file-select-button {
  background: #161f27;
  padding: 10px;
  display: inline-block;
}
.file-upload .file-upload-select .file-select-name {
  display: inline-block;
  padding: 10px;
}
.file-upload .file-upload-select:hover .file-select-button {
  background: #324759;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload .file-upload-select input[type="file"] {
  display: none;
}

.gradient {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(
    -45deg,
    rgb(61, 69, 113),
    rgb(106, 61, 232),
    rgb(48, 64, 158),
    rgb(132, 95, 232)
  );
  background-size: 400% 400%;
  animation: animateGradient 15s ease infinite;
}

.gradient-nav {
  /* height: 100%;
  width: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(
    -45deg,
    rgb(61, 69, 113),
    rgb(106, 61, 232),
    rgb(48, 64, 158),
    rgb(132, 95, 232)
  );
  background-size: 400% 400%;
  animation: animateGradient 15s ease infinite;
}

@keyframes animateGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.sweetalert-custom {
  z-index: 99999 !important; /* Adjust the z-index value as needed */
}
.swal2-container {
  z-index: 5000 !important;
}

.custom-textfield {
  width: 300px;
  height: 4vh;
  background-color: #e1e2e5;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  border: 1px solid #004860;
  padding: 10px;
  border-radius: 50px;
  background-color: transparent; /* Color de fondo del botón */
  color: #000; /* Color del texto del botón */
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9; /* Color de fondo del contenido del dropdown */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px; /* Border-radius en la parte inferior */
}

.dropdown-content p {
  color: black; /* Color del texto de las opciones */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  top: 100%; /* Esto coloca el contenido debajo del botón */
  left: 0; /* Esto coloca el contenido a la izquierda del botón */
}

.dropdown-content p:hover {
  background-color: #ddd; /* Cambio de color al pasar el ratón sobre una opción */
}

.dropdown-container:hover .dropdown-content {
  display: block; /* Mostrar el contenido al pasar el ratón sobre el contenedor */
}

.user {
  border-style: solid;
  --un-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--un-border-opacity));
  border-width: 1px;
  box-sizing: border-box;
  width: 288px;
  height: 216px;

}
.user-name {
  color: rgba(255, 255, 255, 1);
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background-color: rgba(0, 0, 0, 0.4);
  grid-gap: 0.25rem;
  gap: 0.25rem;
  align-items: center;
  display: inline-flex;
  z-index: 2;
  box-sizing: border-box;
  margin-top: 20px;
  margin-left: 20px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px
}
  /* .fc-day-today ;
{
  background: rgba(0, 103, 134, 0.1) !important;
  /* border: none !important; */

.fc .fc-day-disabled {
  /* background: grey; */
  background: rgb(231, 231, 231);
}
.fc {
  max-width: 100%;
}

.fc-toolbar-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fc-view-harness {
  min-width: 0;
}
.fc .fc-button-primary {
  color: var(--fc-button-text-color);
  height: 48px;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  color: var(--accent);
  background-color: #004860;
}
.fc .fc-button-primary:hover {
  background-color: #004860;
  border-color: var(--primary);
  color: var(--fc-button-text-color);
}

@media (max-width: 930px) {
  .fc-header-toolbar {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 8px;
    flex-wrap: wrap;
  }

  .fc-toolbar-chunk {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .fc-header-toolbar > .fc-toolbar-chunk:last-child {
    width: 100%;
    margin-bottom: 8px;
    .fc-button-group {
      width: 100%;
      overflow: hidden;
      -o-border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      border-radius: 8px;
    }
  }

  /* Mover el primer y segundo chunk a la segunda fila */
  .fc-toolbar-chunk:nth-of-type(1),
  .fc-toolbar-chunk:nth-of-type(2) {
    order: 1;
    margin-bottom: -16px;
  }

  /* Mantener el tercer chunk en la primera fila */
  .fc-toolbar-chunk:nth-of-type(3) {
    order: 0;
  }

  .fc-header-toolbar .fc-toolbar-title {
    font-size: 1.2rem;
    margin-left: 10px;
  }
}

.more-links {
  background-color: white;
  color: var(--primary);
  font-weight: bold;
}

.slideInLeft {
  animation-name: slideInLeft; /* Aplica la animación de entrada desde la izquierda */
}

.slideOutLeft {
  animation-name: slideOutLeft; /* Aplica la animación de salida hacia la izquierda */
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
/* .resize {
  border: 1px solid black;
  overflow: auto;
  max-width: 40%; 
  max-height: 70%;
  resize: both;
} */

/* .wrap {
  max-width: 80%;
} */
.userDrawer {
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transición de background-color y color */
}
.secondaryText {
  transition: color 0.5s ease; /* Transición de color */
}

.arrayDoctorsContainer {
  transition: display 0.5s ease; /* Transición de display */
}

.crop-container {
  width: 100%; /* o el ancho deseado */
  height: 300px; /* o la altura deseada */
  position: relative;
}

.crop-container .reactEasyCrop_CropArea {
  border: 2px dashed rgba(255, 255, 255, 0.8); /* Personaliza el estilo del área de recorte si es necesario */
}

/* TextFields personalizados */
.MuiFilledInput-root {
  background-color: rgba(0, 0, 0, 0.08);
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
  border-radius: 12px !important;
  overflow: hidden;
}

/* Estilo por defecto */
.MuiFilledInput-input {
  padding-top: 16px !important;
  padding-bottom: 16px !important;

}

/* Estilo cuando tiene un <label>  como hijos */
.special-case {
  padding-top: 25px !important;
  padding-bottom: 8px !important;
}

.Mui-focused {
  background-color: rgba(22, 102, 131, 0.2) !important; /* Fondo cuando está enfocado */
  color: #006686;
}
.MuiInputBase-input, .MuiFilledInput-input:focus{
  background-color: rgba(0, 0, 0, 0.0) !important;
}
.Mui-focused label:focus{
  background-color: rgba(0, 0, 0, 0.0) !important;
}

.Mui-focused img {
  filter: invert(13%) sepia(91%) saturate(7189%) hue-rotate(186deg) brightness(92%) contrast(101%);
}

.Mui-focused svg {
  color: #006686; /* Cambia el color del ícono SVG cuando está enfocado */
}

.MuiInputBase-root::before {
  border-bottom: none !important; /* Elimina la línea de subrayado antes de enfocar */
}

.text-area>.MuiInputBase-root::after {
  border-bottom: 4px solid #006686; /* Línea de subrayado cuando está enfocado */
}

.text-area .MuiInputAdornment-root {
  display: flex;
  align-items: flex-start;
  height: 100%;
  margin-top: 11px !important;
  margin-bottom: 0 !important;
  position: relative !important;
}

.text-area .MuiFilledInput-input {
  padding-top: 16px; /* Ajusta el padding superior si es necesario */
  padding-bottom: 16px; /* Ajusta el padding inferior si es necesario */
}

.text-area .MuiFilledInput-root {
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  align-items: flex-start;
  height: auto !important;
  position: relative !important;
}
.MuiInputAdornment-root {
  margin-top: 0 !important;
}
.MuiFormControl-root, .MuiTextField-root {
  background-color: rgba(0, 0, 0, 0) !important;

}
.MuiFilledInput-input:-webkit-autofill {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
